.edit-content iframe {
  width: 100%;
  height: 400px;
}

canvas.react-pdf__Page__canvas {
  width: 100% !important;
  margin: 0;
}

.react-pdf__Document::-webkit-scrollbar {
  display: none;
}

.react-pdf__Document {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media only screen and (max-width: 1440px) {
  .edit-content iframe {
    height: 300px;
  }
  /* styles for browsers larger than 1440px; */
}

.plan-wrapper .swiper-wrapper {
  height: fit-content;
  align-items: center;
}

.transcript-source-url > div {
  margin: auto;
}

@media (min-width: 400px) {
  .transcript-source-url > div {
    width: 100% !important;
    height: 300px !important;
  }
}

@media (min-width: 640px) {
  .transcript-source-url > div {
    height: 400px !important;
  }
}


@media (min-width: 768px) {
  .transcript-source-url > div {
    height: 350px !important;
  }
}


@media (min-width: 1024px) {
  
}

@media (min-width: 1280px) {
  
}

@media (min-width: 1536px) {
  .transcript-source-url > div {
    width: 640px !important;
    height: 360px !important;
  }
}